import makeStyles from "@material-ui/styles/makeStyles";

const styles = theme => {
	return makeStyles(theme => ({
		header: {
			background: theme.palette.background.header,
			left: 0,
			position: "sticky",
			right: 0,
			top: 0,
			zIndex: 100,
			[theme.breakpoints.up("md")]: {
				background: "none",
				"& .mco-view-component-header__logo--menuActive": {
					background: "#5f5b57",
					position: "fixed",
					zIndex: 1001,
					"&::before": {
						background: "#5f5b57",
						content: "''",
						height: "100%",
						left: "50%",
						position: "absolute",
						top: 40,
						transform: "translateX(-50%)",
						width: "100vw",
						zIndex: -1
					}
				}
			}
		},
		headerFullscreen: {
			"&::before": {
				background: "linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%)",
				content: "''",
				height: 200,
				left: 0,
				position: "absolute",
				pointerEvents: "none",
				top: 0,
				width: "100%",
				zIndex: -1
			}
		},
		stickyHeader: {
			background: theme.palette.background.header,
			boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.05)",
			"& .mco-view-component-header__container": {
				height: 90
			},
			"&::before": {
				display: "none"
			}
		},
		menuActive: {
			background: theme.palette.background.menu,
			"& .mco-view-component-header-menu-mobilemenu__hamburger": {
				marginRight: "auto"
			},
			"& .mco-view-component-header-lang": {
				marginLeft: 4,
				"& span": {
					color: "#FFFFFF"
				},
				"& svg path": {
					stroke: "#FFFFFF"
				}
			},
			"& .mco-view-component-header-phone": {
				"& span": {
					color: "#FFFFFF"
				},
				"& svg path": {
					fill: "#FFFFFF"
				}
			},
			"&::before": {
				display: "none"
			}
		},
		container: {
			alignItems: "center",
			display: "flex !important",
			columnGap: theme.spacing(2),
			height: 90,
			transition: "height 300ms ease",
			padding: theme.spacing(0, 3) + " !important",
			[theme.breakpoints.up("lg")]: {
				columnGap: theme.spacing(3),
				padding: theme.spacing(0, 5) + " !important"
			},
			"& .mco-view-component-header__lang": {
				display: "none",
				[theme.breakpoints.up("md")]: {
					display: "block"
				}
			}
		}
	}))();
};

export default styles;
